import React, { useState, useEffect } from "react";

import { Box, Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

// Router
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";

//Stores
import authStore from "../../stores/AuthStore";

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");
  useEffect(() => {
    setUserData((data) => ({ ...data, token: token }));
  }, [token]);

  const [userData, setUserData] = useState({
    password: "",
    confirm_new_password: "",
    token: "",
  });

  const validate = () => {
    if (
      userData.password !== userData.confirm_new_password ||
      userData.password === ""
    ) {
      authStore.error = "Please make sure passwords match";
    } else {
      authStore.resetPassword(userData, navigate);
    }
  };
  return (
    <Grid container justifyContent="center" spacing={3}>
      <Box
        clone
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item md={11} xs={11}>
          <img
            style={{ paddingBottom: 15 }}
            src="/bakufind_logo.png"
            alt="logo"
            width="300px"
          />
          <Typography variant="h2" color="primary">
            Reset Password
          </Typography>
          <form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              validate();
            }}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              size="medium"
              name="password"
              label="New Password"
              type="password"
              id="password"
              autoComplete="current-password"
              InputLabelProps={{
                style: { color: "#448cca" },
                shrink: true,
              }}
              value={userData.password}
              onChange={(e) =>
                setUserData({ ...userData, password: e.target.value })
              }
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              size="medium"
              name="confirm_password"
              label="Confirm Password"
              type="password"
              id="confirm_password"
              autoComplete="current-password"
              InputLabelProps={{
                style: { color: "#448cca" },
                shrink: true,
              }}
              value={userData.confirm_new_password}
              onChange={(e) =>
                setUserData({
                  ...userData,
                  confirm_new_password: e.target.value,
                })
              }
            />
            <Button
              style={{ borderRadius: 50, padding: 15, marginTop: 10 }}
              size="large"
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
            >
              SUBMIT
            </Button>
          </form>
        </Grid>
      </Box>
    </Grid>
  );
};

export default ResetPassword;
