import React from "react";

import { Box, Grid } from "@material-ui/core";

const Home = () => {
  return (
    <Grid
      style={{ height: "100vh" }}
      container
      justifyContent="center"
      alignItems="center"
      spacing={3}
    >
      <Box
        clone
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <img alt="logo" src="/bakufind_logo.png" />
      </Box>
    </Grid>
  );
};

export default Home;
