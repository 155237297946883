import { createTheme } from "@material-ui/core/styles";
// 1rem -> 16px

const theme = createTheme({
  palette: {
    common: {
      black: "#232529",
      white: "#fff",
    },
    gray: "#D1D3D4",
    primary: { main: "#448cca", dark: "#448cca", light: "#448cca" },
    secondary: { main: "#ee6c4d", dark: "#ee6c4d", light: "#ee6c4d" },
    text: {
      primary: "#448cca",
      secondary: "",
    },
    background: {
      default: "#ee6c4d",
    },
  },
  typography: {
    fontFamily: "Roboto",
    h1: {
      fontSize: "3.25rem",
      fontWeight: "bold",
      fontFamily: "Roboto",
    },
    h2: {
      fontSize: "2.25rem",
      fontWeight: 500,
      fontFamily: "Roboto",
    },
    h3: {
      fontSize: "1.75rem",
      fontWeight: "bold",
      fontFamily: "Roboto",
    },
    h4: {
      fontSize: "1.5rem",
      fontFamily: "Roboto",
    },
    h5: {
      fontSize: "1rem",
      fontFamily: "Roboto",
    },
    subtitle1: {
      fontSize: "1.125rem",
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: "1rem",
      fontWeight: 500,
    },
    button: {
      fontSize: "1rem",
      fontWeight: "bold",
    },
  },
  overrides: {
    MuiGrid: {
      container: {
        width: "100% !important",
        margin: "0 !important",
      },
    },
  },
});

export default theme;
