import { Route, Routes } from "react-router-dom";

import Home from "./components/Home";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ResetPassword from "./components/ResetPassword";

function App() {
  return (
    <Routes>
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      <Route exact path="/" element={<Home />} />
    </Routes>
  );
}

export default App;
