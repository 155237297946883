import instance from "./instance.js";

class AuthStore {
  resetPassword = async (userData, navigate) => {
    try {
      await instance.post("users/reset-password/confirm/", userData);
      if (navigate) navigate("/");
    } catch (err) {
      this.error = err.response.data;
    }
  };
}

const authStore = new AuthStore();
export default authStore;
